import React from "react"
import remark from 'remark';
import recommended from 'remark-preset-lint-recommended';
import remarkHtml from 'remark-html';
import PreviewCompatibleImage from '../PreviewCompatibleImage'
import Content from '../Content'
import "./TextAndImage.scss"

export default (props) => {

  const { contentComponent, title, image, body } = props;

  const PageContent = contentComponent || Content;

  const convert2Html = (str) => remark().use(recommended).use(remarkHtml).processSync(str).toString();

  if (!title && !image && !body ) {
    return null;
  }

  return (
    <div className="image-text-wrapper">
      <PreviewCompatibleImage
        imageInfo={{
          image: image,
          alt: title,
        }}
      />
      <div className="body">
        <h1>{title}</h1>
        <PageContent content={convert2Html(body)} />
      </div>
    </div>
  )
}

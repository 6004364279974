import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from "../components/layout/layout"
import TextAndImage from "../components/content/TextAndImage"
import { HTMLContent } from '../components/Content'

import "./page.scss"

export const AboutPageTemplate = ({
  data,
  contentComponent
}) => {

  
  let { 
    title_1, title_2, title_3, title_4, title_5, title_6, 
    image_1, image_2, image_3, image_4, image_5, image_6,
    body_1, body_2, body_3, body_4, body_5, body_6
  } = data;

  return(
    <div className="page">
      <TextAndImage contentComponent={contentComponent} title={title_1} image={image_1} body={body_1}></TextAndImage>
      <TextAndImage contentComponent={contentComponent} title={title_2} image={image_2} body={body_2}></TextAndImage>
      <TextAndImage contentComponent={contentComponent} title={title_3} image={image_3} body={body_3}></TextAndImage>
      <TextAndImage contentComponent={contentComponent} title={title_4} image={image_4} body={body_4}></TextAndImage>
      <TextAndImage contentComponent={contentComponent} title={title_5} image={image_5} body={body_5}></TextAndImage>
      <TextAndImage contentComponent={contentComponent} title={title_6} image={image_6} body={body_6}></TextAndImage>
    </div>
  )
}

AboutPageTemplate.propTypes = {
  data: PropTypes.object,
  contentComponent: PropTypes.func,
}

const AboutPage = ({ data }) => {

  const { frontmatter} = data.markdownRemark

  return (
    <Layout className="page">
     <main>
       <section>
        <AboutPageTemplate
          data={frontmatter}
          contentComponent={HTMLContent}
        />
        </section>
      </main>
    </Layout>
  )
}

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default AboutPage

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title_1
        title_2
        title_3
        title_4
        title_5
        title_6
        image_1 {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image_2 {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image_3 {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image_4 {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image_5 {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        image_6 {
          childImageSharp {
            fluid(maxWidth: 1024, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        body_1
        body_2
        body_3
        body_4
        body_5
        body_6
      }
    }
  }
`
